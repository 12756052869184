import { styled } from "@mui/material/styles";

const classes = {
    container: `container`,
    columnsContainer: `columnsContainer`,
    column: `column`,
    textField: `textField`,
    select: `select`,
    selectRow: `selectRow`
};

const Root = styled("div")(({ theme }) => ({
    [`&.${classes.container}`]: {
        padding: "30px 0",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        position: "relative"
    },
    [`& .${classes.columnsContainer}`]: {
        display: "flex",
        flexDirection: "row",
        columnGap: 32,
        marginBottom: 32
    },
    [`& .${classes.column}`]: {
        display: "flex",
        flexDirection: "column",
        width: "100%"
    },
    [`& .${classes.textField}`]: {
        lineHeight: "44px !important",
        // border: "1px solid #B4B3B3",
        "& div.Mui-focused": {
            "& fieldset": {
                borderColor: `${theme.colors.orange[700]} !important`
            }
        },
        "& label.Mui-focused": {
            color: `${theme.colors.orange[700]} !important`
        },
        "& fieldset": {
            borderRadius: "50px",
            "& legend": {
                margin: "4px 0px 4px 2px"
            }
        },
        "& input": {
            padding: "12px 18px !important"
        },
        "& input, div": {
            fontSize: 14
        },
        "& label": {
            transition: "all 100ms linear !important"
        },
        "& label[data-shrink=true].Mui-focused": {
            color: `${theme.colors.orange[700]} !important`
        },
        "& label[data-shrink=true]": {
            padding: `4px${theme.browser.isSafari ? " 10px" : " 8px"}`,
            color: `${theme.colors.grey[900]} !important`,
            background: "white",
            fontWeight: 500,
            fontSize: "16px",
            top: -3,
            "& .MuiFormLabel-asterisk": {
                color: theme.colors.orange[700]
            }
        },
        "& label[data-shrink=false]": {
            paddingLeft: 7
        },
        "& .Mui-disabled+.MuiAutocomplete-inputRoot": {
            opacity: 0.4
        }
    },
    [`& .${classes.centered}`]: {
        textAlign: "center",
        fontWeight: 500,
        fontSize: 12
    },
    "& .copy_data": {
        minHeight: "44px",
        fontSize: "15px"
    },
    [`& .${classes.select}`]: {
        "& .MuiAutocomplete-input": {
            padding: "3px 8px !important"
        },
        "& .MuiAutocomplete-inputRoot": {
            padding: "10px 12px 8px 12px !important"
        }
    },
    [`& .${classes.selectRow}`]: {
        borderRadius: "20px !important",
        "& li": {
            height: 48,
            width: "100%",
            display: "flex !important",
            alignItems: "center !important",
            padding: "0 16px !important",
            background: "white !important",
            "&:hover": {
                background: `${theme.colors.orange[50]} !important`
            }
        },
        "& li[aria-selected='true']": {
            background: `${theme.colors.orange[50]} !important`,
            color: theme.colors.orange[900]
        },
        "& li.dropdown-selected": {
            background: `${theme.colors.orange[50]} !important`,
            color: `${theme.colors.orange[900]} !important`
        }
    }
}));

export { Root, classes };
