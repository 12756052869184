import { styled } from "@mui/material/styles";

const classes = {
    textField: `textField`,
    lessRadioBorder: `lessRadioBorder`,
    switchLabel: `switchLabel`,
    switch: `switch`,
    switchDisabled: `switchDisabled`,
    toggleLabel: `toggleLabel`,
    columnsContainer: `columnsContainer`,
    column: `column`,
    select: `select`,
    selectRow: `selectRow`,
    invisibleColorPicker: `invisibleColorPicker`,
    uploadButton: `uploadButton`,
    imageContainer: `imageContainer`,
    image: `image`,
    removeImage: `removeImage`,
    infoText: "infoText"
};

const Root = styled("div")(({ theme }) => ({
    [`& .${classes.infoText}`]: {
        display: "flex",
        alignItems: "center",
        gap: 8,
        fontSize: 11,
        color: theme.colors.grey[400],
        "& p + p": {
            marginTop: 4
        },
        "& svg": {
            width: 18,
            height: 18
        },
        "& path": {
            fill: theme.colors.grey[400]
        }
    },
    [`& .${classes.textField}`]: {
        lineHeight: "44px !important",
        // border: "1px solid #B4B3B3",
        "& div.Mui-focused": {
            "& fieldset": {
                borderColor: `${theme.colors.orange[700]} !important`
            }
        },
        "& label.Mui-focused": {
            color: `${theme.colors.orange[700]} !important`
        },
        "& fieldset": {
            borderRadius: "50px",
            "& legend": {
                margin: "4px 0px 4px 2px"
            }
        },
        "& input:not([type='color']):not([type='file'])": {
            padding: "12px 18px !important"
        },
        "& input, div": {
            fontSize: 14
        },
        "& label": {
            transition: "all 100ms linear !important"
        },
        "& label[data-shrink=true].Mui-focused": {
            color: `${theme.colors.orange[700]} !important`
        },
        "& label[data-shrink=true]": {
            padding: `4px${theme.browser.isSafari ? " 10px" : " 8px"}`,
            color: `${theme.colors.grey[900]} !important`,
            background: "white",
            fontWeight: 500,
            fontSize: "16px",
            top: -3,
            "& .MuiFormLabel-asterisk": {
                color: theme.colors.orange[700]
            }
        },
        "& label[data-shrink=false]": {
            paddingLeft: 7
        }
    },
    [`& .${classes.lessRadioBorder}`]: {
        "& fieldset": {
            borderRadius: "20px"
        }
    },
    [`& .${classes.switchLabel}`]: {
        "& .MuiFormControlLabel-label": {
            fontSize: 14,
            lineHeight: "16px",
            color: theme.colors.grey[500]
        },
        "&:hover .MuiFormControlLabel-label": {
            color: theme.colors.grey[900]
        },
        "& > * > .Mui-disabled": {
            color: "#EAEAEA !important"
        }
    },
    [`& .${classes.switch}`]: {
        "& .MuiSwitch-switchBase:not(.Mui-disabled)": {
            color: `${theme.colors.orange[700]} !important`,
            "&.Mui-checked:hover": {
                backgroundColor: "rgba(250, 211, 184, 0.15) !important"
            }
        },
        "& .MuiSwitch-switchBase.Mui-disabled": {
            color: "#EAEAEA !important"
        },
        "& .MuiSwitch-track": {
            backgroundColor: `${theme.colors.orange[200]} !important`
        },
        "& .Mui-disabled+.MuiSwitch-track": {
            backgroundColor: `#EAEAEA !important`,
            opacity: "1 !important"
        }
    },
    [`& .${classes.switchDisabled}`]: {
        "&:hover": {
            backgroundColor: "rgba(250, 250, 250, 0.35) !important"
        }
    },
    [`& .${classes.toggleLabel}`]: {
        padding: "0",
        margin: 0,
        fontSize: 12,
        fontWeight: 500,
        lineHeight: "14px"
    },
    [`& .${classes.columnsContainer}`]: {
        display: "flex",
        flexDirection: "row",
        columnGap: 32,
        marginBottom: 32
    },
    [`& .${classes.column}`]: {
        display: "flex",
        flexDirection: "column",
        width: "100%"
    },
    [`& .${classes.select}`]: {
        marginTop: "15px !important",
        "& .MuiSelect-select": {
            padding: "12px 16px 12px 18px !important"
        }
    },
    [`& .${classes.selectRow}`]: {
        borderRadius: "20px !important",
        "& li": {
            height: 48,
            width: "100%",
            display: "flex !important",
            alignItems: "center !important",
            padding: "0 16px !important",
            background: "white !important",
            "&:hover": {
                background: `${theme.colors.orange[50]} !important`
            }
        },
        "& li[aria-selected='true']": {
            background: `${theme.colors.orange[50]} !important`,
            color: theme.colors.orange[900]
        }
    },
    [`& .${classes.invisibleColorPicker}`]: {
        opacity: 0,
        cursor: "pointer",
        padding: "0px !important",
        width: "20px",
        height: "20px",
        "&::-webkit-file-upload-button": {
            cursor: "pointer"
        }
    },
    [`& .${classes.uploadButton}`]: {
        cursor: "pointer",
        height: 44,
        display: "flex",
        alignItems: "center",
        position: "relative",
        "& svg": {
            // margin: "0 11.5px",
            width: 19,
            height: 19,
            "& path": {
                fill: "#9E9E9E"
            },
            transform: "translateY(2px)"
        },
        "&:hover svg": {
            "& path": {
                fill: theme.colors.orange[700]
            }
        }
    },
    [`& .${classes.imageContainer}`]: {
        width: "100%",
        display: "flex",
        height: 200,
        position: "relative",
        alignItems: "center",
        justifyContent: "center",
        padding: 20,
        border: "1px dashed #B4B3B3",
        borderRadius: 10
    },
    [`& .${classes.image}`]: {
        maxWidth: "100%",
        height: "100%",
        objectFit: "scale-down"
    },
    [`& .${classes.removeImage}`]: {
        position: "absolute",
        cursor: "pointer",
        top: 10,
        right: 10,
        display: "flex",
        height: 10
    }
}));

const selectRow = (theme) => ({
    borderRadius: "20px !important",
    boxShadow:
        "0px 5px 5px -3px rgba(0,0,0,0.05), 0px 8px 10px 1px rgba(0,0,0,0.03), 0px 3px 14px 2px rgba(0,0,0,0.1) !important",
    border: "1px solid #d9d9d9 !important",
    "& li": {
        height: 48,
        width: "100%",
        display: "flex !important",
        alignItems: "center !important",
        padding: "0 16px !important",
        background: "white !important",
        "&:hover": {
            background: `${theme.colors.orange[50]} !important`
        }
    },
    "& li[aria-selected='true']": {
        background: `${theme.colors.orange[50]} !important`,
        color: theme.colors.orange[900]
    }
});

export { Root, classes, selectRow };
