import { useContext, useEffect, useState } from "react";
import {
    TextField,
    FormControlLabel,
    Switch,
    Collapse,
    CircularProgress,
    MenuItem
} from "@mui/material";
import {
    Root,
    classes,
    selectRow
} from "../../assets/styles/settings/PresenterSettings.js";
import _ from "lodash";
import Button from "../../components/UI/Button.js";
import AuthGlobal from "../../context/store/global";
import {
    refreshRestaurant,
    updateRestaurant
} from "../../utils/Restaurants/restaurant-helper.js";
import { useAlert } from "../../hooks/useAlert.js";
import Alert from "../../components/UI/Alert.js";
import { useTheme } from "@mui/material/styles";
import Icon from "../../components/UI/Icon.js";

const Checkout = () => {
    const {
        dispatch,
        globalState: {
            current_restaurant: { identifier },
            current_restaurant
        }
    } = useContext(AuthGlobal);

    const defaultFont = {
        name: "Poppins",
        family: "'Poppins', sans-serif",
        url: "https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap",
        weight: "500",
        size: "1rem",
        style: "normal",
        line_height: "1.3rem",
        background: "",
        color: "#000000"
    };

    const [formData, setFormData] = useState({
        instance: "",
        checkout_data: {
            checkout: true,
            guest_location: {
                status: "mandatory",
                skip_if_known: false,
                label: "Location",
                help_text: "",
                header_text: "Can you please confirm your location?"
            },
            guest_name: {
                status: "mandatory",
                skip_if_known: false,
                label: "Full Name",
                header_text:
                    "What is the [name label] [and] [member Id label] for this order?"
            },
            guest_member_id: {
                status: "inactive",
                skip_if_known: false,
                label: "Member ID"
            },
            guest_phone: {
                status: "mandatory",
                skip_if_known: false,
                label: "Phone Number",
                header_text1: "What is a good contact phone number?",
                header_text2:
                    "This is so we can contact you if we have any questions about your order."
            },
            fonts: {
                header_text_1: { ...defaultFont },
                header_text_2: { ...defaultFont }
            },
            api_endpoint:
                "https://backend.milescx.com/api:8NgkMFcN/submit-order",
            session_api: "https://backend.milescx.com/api:8NgkMFcN/sessions"
        }
    });

    const [errors, setErrors] = useState({
        "checkout_data.fonts.header_text_1.color": "",
        "checkout_data.fonts.header_text_2.color": ""
    });

    const [loading, setLoading] = useState(true);
    const [collapsed, setCollapsed] = useState({});
    const [showFormError, setShowFormError] = useState(false);
    const [alert, handleAlert] = useAlert();
    let colorChangeTimeout;

    const theme = useTheme();

    const handleChange = (target, event, type = "text") => {
        let newData = { ...formData };
        if (type !== "image") {
            newData = _.set(newData, target, event.target.value);
            setErrors((prev) => {
                return _.set(errors, target, "");
            });
            setShowFormError(false);
            setFormData(newData);
        }
    };

    useEffect(() => {
        if (current_restaurant?.checkout_data) {
            setFormData((prev) => ({
                ...prev,
                instance: current_restaurant?.instance || "",
                checkout_data: {
                    ...prev.checkout_data,
                    ...current_restaurant?.checkout_data,
                    guest_location: {
                        ...prev.checkout_data?.guest_location,
                        ...current_restaurant.checkout_data?.guest_location
                    },
                    guest_name: {
                        ...prev.checkout_data?.guest_name,
                        ...current_restaurant.checkout_data?.guest_name
                    },
                    guest_member_id: {
                        ...prev.checkout_data?.guest_member_id,
                        ...current_restaurant.checkout_data?.guest_member_id
                    },
                    guest_phone: {
                        ...prev.checkout_data?.guest_phone,
                        ...current_restaurant.checkout_data?.guest_phone
                    },
                    fonts: {
                        ...prev.checkout_data?.fonts,
                        ...current_restaurant.checkout_data?.fonts
                    },
					api_endpoint: current_restaurant.checkout_data?.api_endpoint || "",
					session_api: current_restaurant.checkout_data?.session_api || ""					
                }
            }));
        }
        setLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [identifier]);

    const formInput = (
        label,
        name,
        type = "text",
        { multiline = false } = {}
    ) => {
        const value = _.get(formData, name);
        const errorState = _.get(errors, name)?.length > 0;
        return (
            <>
                <TextField
                    label={label}
                    value={value}
                    type={type !== "color" && type !== "image" ? type : "text"}
                    InputLabelProps={{ shrink: true }}
                    className={`${classes.textField} ${
                        multiline ? classes.lessRadioBorder : ""
                    }`}
                    fullWidth
                    onChange={(e) => handleChange(name, e, type)}
                    style={{ margin: "15px 0" + (errorState ? " -7px" : "") }}
                    multiline={multiline}
                    rows={multiline ? 8.4 : 1}
                    helperText={_.get(errors, name)}
                    error={errorState}
                    InputProps={
                        type === "color"
                            ? {
                                  endAdornment: (
                                      <div
                                          style={{
                                              width: "22px",
                                              height: "22px",
                                              border: "1px solid #B4B3B3",
                                              background: value
                                          }}
                                      >
                                          <input
                                              className={
                                                  classes.invisibleColorPicker
                                              }
                                              style={{
                                                  padding: "0 !important"
                                              }}
                                              type="color"
                                              onChange={(e) => {
                                                  clearTimeout(
                                                      colorChangeTimeout
                                                  );
                                                  colorChangeTimeout =
                                                      setTimeout(() => {
                                                          handleChange(name, e);
                                                      }, 100);
                                              }}
                                          />
                                      </div>
                                  )
                              }
                            : {}
                    }
                />
            </>
        );
    };

    const toggleSwitch = (target) => {
        let newData = { ...formData };
        newData = _.set(newData, target, !_.get(newData, target));
        setFormData(newData);
    };

    const isValidColor = (val) => {
        if (typeof val !== "string") {
            return false;
        }
        const isHex = val.match("^#[0-9a-fA-F]+$");
        const isValidLength = [4, 7, 9].includes(val.length);
        return isHex && isValidLength;
    };

    const handleSubmit = async () => {
        // Validate fields
        const newErrors = {};
        let valid = true;
       // Only validate fields that are supposed to be colors
        Object.keys(errors).forEach((v) => {
            if (v.includes("color")) { // Ensure only color fields are validated
                const color = _.get(formData, v);
                if (color?.length > 0 && !isValidColor(color)) {
                    newErrors[v] = "Invalid color format";
                    valid = false;
                } else {
                    newErrors[v] = "";
                }
            }
        });
        setErrors(newErrors);
        if (!valid) {
            setShowFormError(true);
            window.scrollTo({ top: 200 });
            return;
        }
        // ---- End Validate ----
        let payload = {
            ...formData
        };

        // Call API to update
        const { success, response } = await updateRestaurant(
            identifier,
            payload
        );
        if (success && response) {
            handleAlert({
                severity: "success",
                title: "Success",
                message: "Restaurant updated successfully"
            });
            setTimeout(() => {
                refreshRestaurant(dispatch, identifier, current_restaurant);
            }, 2000);
        }
    };

    const formSwitch = (label, name) => {
        const value = _.get(formData, name);
        return (
            <div style={{ height: 44, margin: "15px 0 0" }}>
                <p className={classes.toggleLabel}>{label}</p>
                <FormControlLabel
                    control={
                        <Switch
                            checked={value}
                            classes={{
                                switchBase: classes.switchDisabled
                            }}
                            className={value ? classes.switch : ""}
                        />
                    }
                    name={name}
                    onChange={() => toggleSwitch(name)}
                    label={value ? "Active" : "Inactive"}
                    className={classes.switchLabel}
                />
            </div>
        );
    };

    const displayName = (str) => {
        return str.split("_").map(_.startCase).join(" ");
    };

    return !loading ? (
        <Root>
            <Alert {...alert} />
            <Collapse in={showFormError}>
                <div
                    style={{
                        fontSize: 14,
                        background: "#FAD3B8",
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                        height: 40
                    }}
                >
                    Some errors were found in the form, please fix them and try
                    again
                </div>
            </Collapse>
            <h2 style={{ marginTop: 15 }}>General</h2>
            <hr style={{ margin: "15px 0" }} />
            <div className={classes.columnsContainer} style={{ margin: 0 }}>
                <div className={classes.column} style={{ width: "50%" }}>
                    {formSwitch("Checkout", "checkout_data.checkout")}
                </div>
                <div className={classes.column} style={{ width: "50%" }}>
                    {formInput("Instance ID", "instance", "text")}
                </div>
            </div>
            <div className={classes.columnsContainer} style={{ margin: 0 }}>
                <div className={classes.column} style={{ width: "50%" }}>
                    {formInput("Session API", "checkout_data.session_api", "text")}
                </div>
                <div className={classes.column} style={{ width: "50%" }}>
                    {formInput("Submit Order API", "checkout_data.api_endpoint", "text")}
                </div>                
            </div>
            <hr style={{ margin: "15px 0" }} />
            <h2>Location</h2>
            <hr style={{ margin: "15px 0" }} />
            <div className={classes.columnsContainer} style={{ margin: 0 }}>
                <div className={classes.column} style={{ width: "50%" }}>
                    <div>
                        <TextField
                            select
                            value={formData.checkout_data.guest_location.status}
                            onChange={(e) =>
                                handleChange(
                                    "checkout_data.guest_location.status",
                                    e
                                )
                            }
                            label="Guest Location Status"
                            InputLabelProps={{ shrink: true }}
                            SelectProps={{
                                MenuProps: {
                                    PaperProps: {
                                        sx: selectRow(theme)
                                    },
                                    classes: {
                                        paper: classes.selectRow
                                    }
                                }
                            }}
                            className={`${classes.select} ${classes.textField}`}
                            style={{ width: "100%" }}
                        >
                            <MenuItem value="inactive"> Inactive</MenuItem>
                            <MenuItem value="mandatory"> Mandatory</MenuItem>
                            <MenuItem value="optional"> Optional</MenuItem>
                        </TextField>
                    </div>
                </div>
                <div className={classes.column} style={{ width: "50%" }}>
                    {formInput(
                        "Location Header Text",
                        "checkout_data.guest_location.header_text",
                        "text"
                    )}
                </div>
            </div>
            <div className={classes.columnsContainer} style={{ margin: 0 }}>
                <div className={classes.column} style={{ width: "50%" }}>
                    {formInput(
                        "Guest Location Label",
                        "checkout_data.guest_location.label",
                        "text"
                    )}
                </div>
                <div className={classes.column} style={{ width: "50%" }}>
                    {formInput(
                        "Location Help Text",
                        "checkout_data.guest_location.help_text",
                        "text"
                    )}
                </div>
            </div>
            <div className={classes.columnsContainer} style={{ margin: 0 }}>
                <div className={classes.column} style={{ width: "50%" }}>
                    {formSwitch(
                        "Guest Location Skip If Know",
                        "checkout_data.guest_location.skip_if_known"
                    )}
                </div>
            </div>
            <hr style={{ margin: "15px 0" }} />
            <h2>Name</h2>
            <hr style={{ margin: "15px 0" }} />
            <div className={classes.columnsContainer} style={{ margin: 0 }}>
                <div className={classes.column} style={{ width: "50%" }}>
                    <div>
                        <TextField
                            select
                            value={formData.checkout_data.guest_name.status}
                            onChange={(e) =>
                                handleChange(
                                    "checkout_data.guest_name.status",
                                    e
                                )
                            }
                            label="Guest Name Status"
                            InputLabelProps={{ shrink: true }}
                            SelectProps={{
                                MenuProps: {
                                    PaperProps: {
                                        sx: selectRow(theme)
                                    },
                                    classes: {
                                        paper: classes.selectRow
                                    }
                                }
                            }}
                            style={{ width: "100%" }}
                            className={`${classes.select} ${classes.textField}`}
                        >
                            <MenuItem value="inactive"> Inactive</MenuItem>
                            <MenuItem value="mandatory"> Mandatory</MenuItem>
                            <MenuItem value="optional"> Optional</MenuItem>
                        </TextField>
                    </div>
                </div>
                <div className={classes.column} style={{ width: "50%" }}>
                    {formInput(
                        "Guest Name Label",
                        "checkout_data.guest_name.label",
                        "text"
                    )}
                </div>
            </div>
            <div className={classes.columnsContainer} style={{ margin: 0 }}>
                <div className={classes.column} style={{ width: "50%" }}>
                    {formSwitch(
                        "Guest Name Skip If Know",
                        "checkout_data.guest_name.skip_if_know"
                    )}
                </div>
                <div className={classes.column} style={{ width: "50%" }}>
                    {formInput(
                        "Guest Name Header Text",
                        "checkout_data.guest_name.header_text",
                        "text"
                    )}
                    <h5 className={classes.infoText}>
                        <Icon name="info" />
                        <div>
                            <p>
                                <b>[name label]</b>: Takes value from Guest Name
                                Label.{" "}
                            </p>
                            <p>
                                <b>[and]</b>: Shown only if both Guest Name
                                Label and Guest Member ID Label exist.
                            </p>
                            <p>
                                <b>[member Id label]</b>: Takes value from Guest
                                Member ID Label.
                            </p>
                            <p>
                                If neither value exists or is updated, the{" "}
                                <b>Guest Name Header Text</b> will remain static
                                as entered.
                            </p>
                        </div>
                    </h5>
                </div>
            </div>
            <hr style={{ margin: "15px 0" }} />
            <h2>Member ID</h2>
            <hr style={{ margin: "15px 0" }} />
            <div className={classes.columnsContainer} style={{ margin: 0 }}>
                <div className={classes.column} style={{ width: "50%" }}>
                    <div>
                        <TextField
                            select
                            value={
                                formData.checkout_data.guest_member_id.status
                            }
                            onChange={(e) =>
                                handleChange(
                                    "checkout_data.guest_member_id.status",
                                    e
                                )
                            }
                            label="Guest Member ID Status"
                            InputLabelProps={{ shrink: true }}
                            SelectProps={{
                                MenuProps: {
                                    PaperProps: {
                                        sx: selectRow(theme)
                                    },
                                    classes: {
                                        paper: classes.selectRow
                                    }
                                }
                            }}
                            style={{ width: "100%" }}
                            className={`${classes.select} ${classes.textField}`}
                        >
                            <MenuItem value="inactive"> Inactive</MenuItem>
                            <MenuItem value="mandatory"> Mandatory</MenuItem>
                            <MenuItem value="optional"> Optional</MenuItem>
                        </TextField>
                    </div>
                </div>
                <div className={classes.column} style={{ width: "50%" }}>
                    {formInput(
                        "Guest Member ID Label",
                        "checkout_data.guest_member_id.label",
                        "text"
                    )}
                </div>
            </div>
            <div className={classes.columnsContainer} style={{ margin: 0 }}>
                <div className={classes.column} style={{ width: "50%" }}>
                    {formSwitch(
                        "Guest Member ID Skip If Know",
                        "checkout_data.guest_member_id.skip_if_know"
                    )}
                </div>
            </div>
            <hr style={{ margin: "15px 0" }} />
            <h2>Phone</h2>
            <hr style={{ margin: "15px 0" }} />
            <div className={classes.columnsContainer} style={{ margin: 0 }}>
                <div className={classes.column} style={{ width: "50%" }}>
                    <div>
                        <TextField
                            select
                            value={formData.checkout_data.guest_phone.status}
                            onChange={(e) =>
                                handleChange(
                                    "checkout_data.guest_phone.status",
                                    e
                                )
                            }
                            label="Guest Phone Status"
                            InputLabelProps={{ shrink: true }}
                            SelectProps={{
                                MenuProps: {
                                    PaperProps: {
                                        sx: selectRow(theme)
                                    },
                                    classes: {
                                        paper: classes.selectRow
                                    }
                                }
                            }}
                            style={{ width: "100%" }}
                            className={`${classes.select} ${classes.textField}`}
                        >
                            <MenuItem value="inactive"> Inactive</MenuItem>
                            <MenuItem value="mandatory"> Mandatory</MenuItem>
                            <MenuItem value="optional"> Optional</MenuItem>
                        </TextField>
                    </div>
                </div>
                <div className={classes.column} style={{ width: "50%" }}>
                    {formInput(
                        "Guest Phone Header Text 1",
                        "checkout_data.guest_phone.header_text1",
                        "text"
                    )}
                </div>
            </div>
            <div className={classes.columnsContainer} style={{ margin: 0 }}>
                <div className={classes.column} style={{ width: "50%" }}>
                    {formInput(
                        "Guest Phone Label",
                        "checkout_data.guest_phone.label",
                        "text"
                    )}
                </div>
                <div className={classes.column} style={{ width: "50%" }}>
                    {formInput(
                        "Guest Phone Header Text 2",
                        "checkout_data.guest_phone.header_text2",
                        "text"
                    )}
                </div>
            </div>
            <div className={classes.columnsContainer} style={{ margin: 0 }}>
                <div className={classes.column} style={{ width: "50%" }}>
                    {formSwitch(
                        "Guest Phone Skip If Know",
                        "checkout_data.guest_phone.skip_if_know"
                    )}
                </div>
            </div>
            <hr style={{ margin: "15px 0" }} />
            <h2>Fonts</h2>
            <hr style={{ marginTop: "15px" }} />
            {Object?.keys(formData?.checkout_data?.fonts).map((key, index) => (
                <div key={key}>
                    <h4
                        style={{
                            margin: "0px",
                            padding: "10px",
                            borderBottom: "1px solid",
                            borderTop: index !== 0 ? "1px solid" : "",
                            textAlign: "center",
                            cursor: "pointer",
                            width: "100%",
                            height: "100%"
                        }}
                        onClick={() => {
                            setCollapsed((prev) => ({
                                ...prev,
                                [key]: !prev[key]
                            }));
                        }}
                    >
                        {displayName(key)}
                    </h4>
                    <Collapse in={collapsed[key]}>
                        <div className={classes.columnsContainer}>
                            <div className={classes.column}>
                                {formInput(
                                    "Name",
                                    `checkout_data.fonts.${key}.name`
                                )}
                                {formInput(
                                    "Weight",
                                    `checkout_data.fonts.${key}.weight`
                                )}
                                {formInput(
                                    "Line height",
                                    `checkout_data.fonts.${key}.line_height`
                                )}
                            </div>
                            <div className={classes.column}>
                                {formInput(
                                    "Family",
                                    `checkout_data.fonts.${key}.family`
                                )}
                                {formInput(
                                    "Size",
                                    `checkout_data.fonts.${key}.size`
                                )}
                                {formInput(
                                    "Color",
                                    `checkout_data.fonts.${key}.color`,
                                    "color"
                                )}
                            </div>
                            <div className={classes.column}>
                                {formInput(
                                    "Url",
                                    `checkout_data.fonts.${key}.url`
                                )}
                                {formInput(
                                    "Style",
                                    `checkout_data.fonts.${key}.style`
                                )}
                            </div>
                        </div>
                    </Collapse>
                </div>
            ))}
            <Button
                innerText="Save"
                type="primary"
                color="orange"
                style={{ marginLeft: "auto", marginBottom: 15, marginTop: 15 }}
                onClick={handleSubmit}
            />
        </Root>
    ) : (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: 500
            }}
        >
            <CircularProgress
                style={{ color: "#CC5803", width: 60, height: 60 }}
            />
        </div>
    );
};

export default Checkout;
