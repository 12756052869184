import { useContext, useEffect, useState } from "react";
import { Autocomplete, Box, CircularProgress, TextField } from "@mui/material";
import {
    Root,
    classes
} from "../../assets/styles/pages/DuplicateRestaurant.js";

import AuthGlobal from "../../context/store/global";
import { useAlert } from "../../hooks/useAlert.js";
import Alert from "../../components/UI/Alert.js";
import Button from "../../components/UI/Button.js";
import { cloneRestaurant } from "../../utils/Restaurants/restaurant-helper.js";

const DuplicateRestaurant = () => {
    const {
        globalState: {
            user,
            current_restaurant: { identifier }
        }
    } = useContext(AuthGlobal);

    const [loading, setLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [options, setOptions] = useState([]);
    const [restaurantOption, setRestaurantOption] = useState(null);
    const [alert, handleAlert] = useAlert();

    useEffect(() => {
        let opt = user?.user_permissions;
    
        // Filter out permissions based on role
        if (user.role !== "super_admin") {
            opt = opt?.filter((x) => x.status === "active");
        }
    
        // Filter permissions based on "active" restaurant status
        opt = opt
            .filter((x) => x?.identifier?.status === "active") // Check for active status
            .filter((x) => x?.identifier?.identifier !== identifier) // Exclude the current identifier
    
        // Sort options by display_name with accent sensitivity
        opt = opt.sort((x, y) =>
            x?.identifier?.display_name.localeCompare(y?.identifier?.display_name, undefined, { sensitivity: "accent" })
        );
    
        // Remove duplicates by creating a Map and converting it back to an array
        const uniqueOptions = Array.from(new Map(opt.map((x) => [x?.identifier?.identifier, x])).values());
    
        // Map the options into the required format
        const transformedOptions = uniqueOptions.map((x) => ({
            label: x?.identifier?.display_name,
            value: x?.identifier?.identifier
        }));
    
        // Set options and stop loading
        setOptions(transformedOptions);
        setLoading(false);
    }, [user, identifier]);    

    const handleCopyData = async () => {
        setIsSaving(true); // Indicate saving has started
        try {
            const identifierToCopy = restaurantOption?.value;
    
            if (!identifierToCopy) {
                handleAlert({
                    severity: "info",
                    title: "Warning",
                    message: "Please select a restaurant to copy data from."
                });
                setIsSaving(false);
                return;
            }
    
            const { success, response } = await cloneRestaurant(identifier, identifierToCopy);
    
            if (success && response) {
                handleAlert({
                    severity: "success",
                    title: "Success",
                    message: "Restaurant copied successfully."
                });
            } else {
                handleAlert({
                    severity: "error",
                    title: "Error",
                    message: "Failed to copy restaurant data. Please try again."
                });
            }
        } catch (error) {
            console.error("Error copying restaurant data:", error);
            handleAlert({
                severity: "error",
                title: "Error",
                message: "An unexpected error occurred while copying restaurant data."
            });
        } finally {
            setIsSaving(false); // Ensure saving state is reset regardless of outcome
        }
    };
    

    return !loading ? (
        <Root>
            <Alert {...alert} />
            <Box
                component="h2"
                sx={{
                    fontSize: "18px",
                    margin: "32px 0 25px"
                }}
            >
                Select a restaurant to copy data from:
            </Box>
            <div className={classes.columnsContainer}>
                <div className={classes.column}>
                    <Autocomplete
                        fullWidth
                        value={restaurantOption}
                        onChange={(_e, v) => setRestaurantOption(v)}
                        options={options}
                        disableClearable={true}
                        disablePortal
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Restaurant"
                                placeholder="Select a restaurant"
                                InputLabelProps={{
                                    shrink: true
                                }}
                                className={`${classes.select} ${classes.textField}`}
                            />
                        )}
                        componentsProps={{
                            paper: {
                                className: classes.selectRow
                            }
                        }}
                        isOptionEqualToValue={(option, value) =>
                            option.label === value.label
                        }
                    />
                </div>
                <div className={classes.column}>
                    <div>
                        <Button
                            hasLoading={isSaving}
                            disableBtn={isSaving}
                            innerText="Save"
                            type="primary"
                            color="dark"
                            className="save_btn copy_data"
                            onClick={handleCopyData}
                        />
                    </div>
                </div>
            </div>
        </Root>
    ) : (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: 500
            }}
        >
            <CircularProgress
                style={{ color: "#CC5803", width: 60, height: 60 }}
            />
        </div>
    );
};

export default DuplicateRestaurant;
