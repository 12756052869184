import { MenuItem, Modal, Slide, TextField } from "@mui/material";
import Cookie from "js-cookie";
import { useContext, useEffect, useState } from "react";
import {
    Root,
    classes,
    selectRow
} from "../../assets/styles/settings/FormCreateUser";
import Button from "../../components/UI/Button";
import Icon from "../../components/UI/Icon";
import { useAlert } from "../../hooks/useAlert";
import { client } from "../../utils/apiClient";
import AuthGlobal from "../../context/store/global";
import Alert from "../../components/UI/Alert";
import { useIntercom } from "react-use-intercom";
import { useTheme } from "@mui/material/styles";
import { isValidEmail } from "../../utils/validation";

const FormCreateUser = ({ open, handleClose, mode = "create", user = {} }) => {
    const token = Cookie.get("sm_c_t");

    const {
        globalState: {
            current_restaurant: { _id, identifier },
            user: authenticatedUser
        }
    } = useContext(AuthGlobal);
    const { update } = useIntercom();
    useEffect(() => {
        update({
            alignment: open ? "left" : "right"
        });
    }, [open, update]);

    const authUser_role =
        authenticatedUser.user_permissions.find(
            (x) => x.identifier.identifier === identifier
        )?.user_role || "user";

    const [active, setActive] = useState(true);
    const [errorText, setErrorText] = useState("");
    const [lastNameErrorText, setLastNameErrorText] = useState("");
    const [emailErrorText, setEmailErrorText] = useState("");
    const [roleError, setRoleError] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [role, setRole] = useState("");
    const [loading, setLoading] = useState(false);

    const [alert, handleAlert] = useAlert();

    const theme = useTheme();

    useEffect(() => {
        if (mode === "update") {
            setActive(user.status === "active");
            setFirstName(user.first_name);
            setLastName(user.last_name);
            setEmail(user.email);
            setRole(getLabel(user.role));
        }
    }, [mode, user]);

    useEffect(() => {
        setRoleError("");
    }, [role]);

    const handleSubmit = async () => {
        if (!firstName || !role || !lastName || !isValidEmail(email)) {
            !firstName
                ? setErrorText("* Please, enter a valid name")
                : setErrorText("");
            !lastName
                ? setLastNameErrorText("* Please, enter a valid name")
                : setLastNameErrorText("");
            !isValidEmail(email)
                ? setEmailErrorText("* Please, enter a valid email")
                : setEmailErrorText("");
            !role
                ? setRoleError("* Please, complete this field")
                : setRoleError("");

            return;
        }

        const payload = {
            _id: user._id,
            first_name: firstName,
            last_name: lastName,
            email,
            user_role: getValue(role),
            status: active ? "active" : "inactive",
            identifier
        };
        if (mode === "create") {
            const { success, response } = await client(
                "user/admin-create-user",
                payload,
                "POST",
                {
                    Authorization: `${token}`
                }
            );
            if (success && response) {
                handleAlert({
                    severity: "success",
                    title: "Success",
                    message: "User successfully created"
                });
                handleClose();
                clearForm();
            } else {
                handleAlert({
                    severity: "error",
                    title: "Error",
                    message: response.error
                });
                if (firstName || role || lastName || isValidEmail(email)) {
                    !firstName
                        ? setErrorText("* Please, enter a valid name")
                        : setErrorText("");
                    !lastName
                        ? setLastNameErrorText("* Please, enter a valid name")
                        : setLastNameErrorText("");
                    !isValidEmail(email)
                        ? setEmailErrorText("* Please, enter a valid email")
                        : setEmailErrorText("");
                    !role
                        ? setRoleError("* Please, complete this field")
                        : setRoleError("");
                }
            }
        }
    };

    const addToRestaurant = async () => {
        if (!role || !isValidEmail(email)) {
            !isValidEmail(email)
                ? setEmailErrorText("* Please, enter a valid email")
                : setEmailErrorText("");
            !role
                ? setRoleError("* Please, complete this field")
                : setRoleError("");

            return;
        }
        const payload = {
            email,
            user_role: getValue(role),
            restaurant_id: _id,
            identifier
        };

        const { success, response } = await client(
            "user/add-user-to-restaurant",
            payload,
            "POST",
            {
                Authorization: `${token}`
            }
        );
        if (success && response) {
            handleAlert({
                severity: "success",
                title: "Success",
                message: "User successfully updated"
            });
            handleClose();
            clearForm();
        } else {
            handleAlert({
                severity: "error",
                title: "Error",
                message: response.message || response.error
            });
            if (role || isValidEmail(email)) {
                !isValidEmail(email)
                    ? setEmailErrorText("* Please, enter a valid email")
                    : setEmailErrorText("");
                !role
                    ? setRoleError("* Please, complete this field")
                    : setRoleError("");

                return;
            }
        }
    };

    const getValue = (label) => {
        if (label === "Administrator") {
            return "admin";
        }
        if (label === "User") {
            return "user";
        }
        if (label === "Customer Support") {
            return "customer_support";
        }
        if (label === "View Only") {
            return "view_only";
        }
        return "account_owner";
    };

    const getLabel = (value) => {
        if (value === "admin") {
            return "Administrator";
        }
        if (value === "user") {
            return "User";
        }
        if (value === "Customer Support") {
            return "customer_support";
        }
        if (value === "View Only") {
            return "view_only";
        }
        return "Account Owner";
    };

    const clearForm = () => {
        setFirstName("");
        setLastName("");
        setActive(true);
        setEmail("");
        setRole("");
        setErrorText("");
        setLastNameErrorText("");
        setEmailErrorText("");
        setRoleError("");
    };

    const onClose = () => {
        clearForm("");
        handleClose();
    };

    return (
        <>
            <Alert {...alert} />
            <Modal open={open} onClose={onClose} disablePortal>
                <Slide in={open} direction="left">
                    <Root className={classes.container}>
                        <div className={classes.top}>
                            <span className={classes.sectionClose}>
                                <span
                                    className={classes.closeButtonContainer}
                                    onClick={onClose}
                                >
                                    <Icon
                                        name="clear"
                                        className={classes.closeButton}
                                    />
                                </span>
                            </span>
                            <h1 className={classes.title}>
                                {mode === "create"
                                    ? "Add New User"
                                    : mode === "update"
                                    ? "Edit User"
                                    : "Add Existing User"}
                            </h1>
                        </div>
                        <div className={classes.form}>
                            {mode !== "invite" && (
                                <>
                                    {/* <p className={classes.toggleLabel}>
                                        User Status
                                    </p> */}
                                    {/* <FormControlLabel
                                        control={
                                            <Switch
                                                checked={active}
                                                classes={{
                                                    switchBase:
                                                        classes.switchDisabled
                                                }}
                                                className={
                                                    active ? classes.switch : ""
                                                }
                                            />
                                        }
                                        disabled={
                                            mode === "update" &&
                                            user.role === "account_owner"
                                        }
                                        label={active ? "Active" : "Inactive"}
                                        onChange={() =>
                                            setActive((val) => !val)
                                        }
                                        className={classes.switchLabel}
                                    /> */}
                                    <TextField
                                        required
                                        fullWidth
                                        error={errorText.length > 0}
                                        helperText={errorText}
                                        placeholder="Enter User First Name"
                                        className={classes.textField}
                                        label="First Name"
                                        value={firstName}
                                        InputLabelProps={{ shrink: true }}
                                        onChange={(event) => {
                                            setFirstName(event.target.value);
                                            if (event.target.value.length > 0) {
                                                setErrorText("");
                                            } else {
                                                setErrorText(
                                                    "* Please, enter a valid name"
                                                );
                                            }
                                        }}
                                    />
                                    <TextField
                                        required
                                        fullWidth
                                        error={lastNameErrorText.length > 0}
                                        helperText={lastNameErrorText}
                                        placeholder="Enter User Last Name"
                                        className={classes.textField}
                                        label="Last Name"
                                        value={lastName}
                                        InputLabelProps={{ shrink: true }}
                                        onChange={(event) => {
                                            setLastName(event.target.value);
                                            if (event.target.value.length > 0) {
                                                setLastNameErrorText("");
                                            } else {
                                                setLastNameErrorText(
                                                    "* Please, enter a valid name"
                                                );
                                            }
                                        }}
                                    />
                                </>
                            )}

                            <TextField
                                required
                                fullWidth
                                error={emailErrorText.length > 0}
                                helperText={emailErrorText}
                                placeholder="Enter User Email"
                                className={classes.textField}
                                disabled={user.role === "account_owner"}
                                label="User Email"
                                value={email}
                                InputLabelProps={{ shrink: true }}
                                onChange={(event) => {
                                    setEmail(event.target.value);
                                    if (isValidEmail(event.target.value)) {
                                        setEmailErrorText("");
                                    } else {
                                        setEmailErrorText(
                                            "* Please, enter a valid email"
                                        );
                                    }
                                }}
                            />
                            {user.role !== "account_owner" ? (
                                <TextField
                                    select
                                    value={role}
                                    required
                                    helperText={roleError}
                                    error={roleError.length > 0}
                                    onChange={(e) => setRole(e.target.value)}
                                    label="User Role"
                                    placeholder="Select User Role"
                                    InputLabelProps={{ shrink: true }}
                                    SelectProps={{
                                        MenuProps: {
                                            PaperProps: {
                                                sx: selectRow(theme)
                                            },
                                            classes: {
                                                paper: classes.selectRow
                                            }
                                        }
                                    }}
                                    className={`${classes.select} ${classes.textField}`}
                                    disabled={
                                        mode === "update" &&
                                        user._id === authenticatedUser._id
                                    }
                                    classes={{
                                        textFieldBase: classes.textFieldDisabled
                                    }}
                                >
                                    <MenuItem value="Administrator">
                                        Administrator
                                    </MenuItem>
                                    <MenuItem value="User">User</MenuItem>
                                    {authenticatedUser.role ===
                                        "super_admin" && (
                                        <MenuItem value="Customer Support">
                                            Customer Support
                                        </MenuItem>
                                    )}
                                    {(authUser_role ===
                                        "admin" || authenticatedUser.role ===
                                        "super_admin" || authUser_role ===
                                        "customer_support" || authUser_role ===
                                        "account_owner") && (
                                        <MenuItem value="View Only">
                                            View Only
                                        </MenuItem>
                                    )}
                                </TextField>
                            ) : (
                                <TextField
                                    fullWidth
                                    required
                                    disabled
                                    className={classes.textField}
                                    label="User Role"
                                    value={"Account Owner"}
                                    InputLabelProps={{ shrink: true }}
                                />
                            )}
                        </div>

                        <div className={classes.actions}>
                            <Button
                                type="primary"
                                color="orange"
                                innerText="Save"
                                disabled={loading}
                                onClick={() => {
                                    setLoading(true);
                                    if (mode !== "invite") {
                                        handleSubmit()
                                            .then(() => setLoading(false))
                                            .catch((e) => {
                                                handleAlert({
                                                    title: "Error",
                                                    severity: "error",
                                                    message: e.message
                                                });
                                                handleClose();
                                                clearForm();
                                                setLoading(false);
                                                return;
                                            });
                                    } else {
                                        addToRestaurant().then(() =>
                                            setLoading(false)
                                        );
                                    }
                                }}
                            />

                            <Button
                                type="secondary"
                                color="dark"
                                innerText="Cancel"
                                onClick={() => onClose()}
                            />
                        </div>
                    </Root>
                </Slide>
            </Modal>
        </>
    );
};

export default FormCreateUser;
